import React, { lazy } from 'react';

const EInvoiceReportingSubmenu = lazy(() =>
  import('./EInvoiceReportingSubmenu').then(module => ({
    default: module.EInvoiceReportingSubmenu,
  })),
);

const EInvoiceListingReportParameter = lazy(() =>
  import('./ParameterPage/EInvoiceListingReportParameter').then(module => ({
    default: module.EInvoiceListingReportParameter,
  })),
);

const EInvoiceListingReportPreview = lazy(() =>
  import('./PreviewPage/EInvoiceListingReportPreview').then(module => ({
    default: module.EInvoiceListingReportPreview,
  })),
);

const einvoiceReportingRoutes = [
  {
    props: {
      exact: true,
      path: '/financial-accounting/einvoice-reporting',
    },
    component: <EInvoiceReportingSubmenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/financial-accounting/einvoice-reporting/parameter/einvoice-listing-report',
    },
    component: <EInvoiceListingReportParameter />,
  },
  {
    props: {
      exact: true,
      path:
        '/financial-accounting/einvoice-reporting/parameter/einvoice-listing-report/view',
    },
    component: <EInvoiceListingReportPreview />,
  },
];
export default einvoiceReportingRoutes;
